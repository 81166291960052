/**
 * @author syt123450 / https://github.com/syt123450
 */

function ModelRenderer( tspModel, handlers ) {
	
	this.tspModel = tspModel;
	this.handlers = handlers;
	
}

ModelRenderer.prototype = {
	
	init: function() {
	
	},
	
	reset: function() {
	
	}
	
};

export { ModelRenderer };